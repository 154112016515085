import { render, staticRenderFns } from "./virtual-card-instructions-modal.vue?vue&type=template&id=0755aa70&scoped=true&"
import script from "./virtual-card-instructions-modal.ts?vue&type=script&lang=ts&"
export * from "./virtual-card-instructions-modal.ts?vue&type=script&lang=ts&"
import style0 from "./virtual-card-instructions-modal.vue?vue&type=style&index=0&id=0755aa70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0755aa70",
  null
  
)

export default component.exports